<template>
  <aside>made with Vue.js, last updated in may 2021 <i class="fas fa-mug-hot fa-xs"></i> </aside>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style>
aside{
    font-size: 1rem;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 10px;
}
</style>